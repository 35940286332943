@charset "UTF-8";


/*
 * Note: Base64 fonts are loaded in app-load.css referenced in index.html.
 * The styles below will be compiled by webpack.
 */
 

/* Font icon styles */
[data-icon]:before {
  font-family: "acca-compass" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "acca-compass" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-plus-circled-1:before       { content: "\61"; }
.icon-plus-circled-2:before       { content: "\62"; }
.icon-delete-thin:before          { content: "\63"; }
.icon-delete-thick:before         { content: "\64"; }
.icon-chevron-dn:before           { content: "\65"; }
.icon-chevron-lt:before           { content: "\66"; }
.icon-chevron-rt:before           { content: "\67"; }
.icon-chevron-up:before           { content: "\68"; }
.icon-edit-pencil-filled:before   { content: "\69"; }
.icon-edit-pencil-line:before     { content: "\6a"; }
.icon-edit-pencil-hollow:before   { content: "\6b"; }
.icon-cross-thin:before           { content: "\6c"; }
.icon-cross-thick:before          { content: "\6d"; }


/*
 * Define CSS variables.
 */
 
:root {
  --col-wht: rgb(250, 250, 250);
  --col-blk: rgb(10, 10, 10);
  --col-gry: rgb(150, 150, 150);
  --col-gry-lgt: rgb(225, 225, 225);
  --col-gry-drk: rgb(70, 70, 70);
  --col-red: rgb(194, 0, 0);
  --col-oge: rgb(238, 113, 17); 
  --col-yel: rgba(255, 190, 0);
  --col-grn: rgb(78, 160, 0);
  --col-grn: rgb(78, 160, 0);
  
  --col-bkgrnd-wht-05: rgba(255, 255, 255, 0.05);
  --col-bkgrnd-wht-15: rgba(255, 255, 255, 0.15);
  --col-bkgrnd-blu: rgb(38, 61, 83);
  --col-bkgrnd-drk-blu: rgb(25, 40, 54);
  --col-bkgrnd-drk-blu-6: rgba(10, 16, 22, 0.6);
  --col-bkgrnd-drk-blu-8: rgba(10, 16, 22, 0.8);
  --col-bkgrnd-drk-blu-9: rgba(10, 16, 22, 0.9);
  
  --col-bkgrnd-chevron-1: rgb(21, 35, 48);
  --col-bkgrnd-chevron-2: rgb(69, 88, 106);
  --col-bkgrnd-chevron-3: rgb(27, 42, 57);
  
  --fnt-col-dim: rgb(143, 151, 159);
  --fnt-col-lgt: rgb(252, 252, 252);
  --fnt-col-mid: rgb(150, 150, 150);
  --fnt-col-drk: rgb(12, 12, 12);
  --fnt-col-drkish: rgb(80, 80, 80);
  
  --fnt-sze-tny: 1.2rem;
  --fnt-sze-sml: 1.3rem;
  --fnt-sze-med: 1.5rem;
  --fnt-sze-lrg: 1.7rem;
  --fnt-sze-hge: 1.9rem;
  --fnt-sze-mve: 2.4rem;
  
  --fnt-wgt-lgt: 300;
  --fnt-wgt-reg: 400;
  --fnt-wgt-med: 500;
  --fnt-wgt-bld: 700;
  
  --lne-hgt-sml: 1.6rem;
  --lne-hgt-med: 2rem;
  --lne-hgt-lrg: 2.4rem;
  --lne-hgt-mve: 3rem;
  
  --fnt-roboto: 'Roboto', Sans-Serif;
  --fnt-roboto-slab: 'Roboto Slab', Serif;
  --fnt-compass: 'acca-compass';
  
  --brdr-rad-4: 4px;
  --brdr-rad-6: 6px;
  --brdr-rad-8: 8px;
  
  --brdr-solid-gry: 1px solid rgb(200, 200, 200);
  --brdr-solid-gryish: 1px solid rgb(220, 220, 220);
  --brdr-solid-wht: 1px solid rgb(250, 250, 250);
  --brdr-solid-wht-15: 1px solid rgba(255, 255, 255, 0.15);
  --brdr-solid-wht-2: 2px solid rgb(250, 250, 250);
  --brdr-solid-wht-4: 4px solid rgb(250, 250, 250);
  --brdr-solid-red: 1px solid rgb(225, 2, 0);
  --brdr-dashed-wht: 1px dashed rgb(250, 250, 250);
  --brdr-dashed-gry: 1px dashed rgb(100, 100, 100);
  --brdr-dashed-red: 1px dashed rgb(225, 2, 0);
  
  --box-shadow-lgt: 0px 0px 3px 2px rgba(0, 0, 0, 0.10);
  --box-shadow-med: 0px 0px 5px 4px rgba(0, 0, 0, 0.10);
  
  --bkgrnd-mask-50: url('./assets/img/bkgrnd-mask-50.png');
  --bkgrnd-mask-65: url('./assets/img/bkgrnd-mask-65.png');
  --bkgrnd-mask-70: url('./assets/img/bkgrnd-mask-70.png');
  --bkgrnd-mask-80: url('./assets/img/bkgrnd-mask-80.png');
  --bkgrnd-mask-90: url('./assets/img/bkgrnd-mask-90.png');
}



/*
 * Reset browser styles.
 */
 
html, body { height: 100%; }

html, body, div, span, p, nav, input, textarea,
button, iframe, h1, h2, h3, h4, h5, h6, a, form,
label, table, tbody, tfoot, thead, footer, header,
nav, ul, li { 
  margin: 0; 
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}

div, span, p, nav, button,
h1, h2, h3, h4, h5, h6, a, 
label, table, nav, ul, li {
  user-select: none;
}

/* Set root font size to 10px so we can use rem's & em's. */
html { font-size: 62.5%; }

ul { list-style-type: none; }

table {
  border-collapse: collapse;
  border-spacing: 0;
}

label, button, a { user-select: none; }

button { 
  cursor: pointer; 
  display: inline-block;
  position: relative;
  opacity: 1;
  color: var(--fnt-col-lgt);
  font-size: var(--fnt-sze-med);
  font-family: var(--fnt-roboto);
  background-color: transparent;
  transition: none;
}

button.disabled,
button:disabled { 
  cursor: default; 
  opacity: 0.4;
  pointer-events: none;
  transition: opacity 0.2s ease-out 0.3s;
}

::placeholder {
  color: var(--fnt-col-lgt);
  opacity: 0.7;
}

:-ms-input-placeholder {
  color: var(--fnt-col-lgt);
  opacity: 0.7;
}

::-ms-input-placeholder {
  color: var(--fnt-col-lgt);
  opacity: 0.7;
}

[contenteditable=true]:empty::before { 
  content: attr(placeholder); 
  color: var(--fnt-col-lgt);
  opacity: 0.7;
}

::-webkit-scrollbar { 
  width: 8px;
  height:6px; 
}
::-webkit-scrollbar-track { background-color: rgba(255, 255, 255, 0.1); }
::-webkit-scrollbar-thumb { background-color: rgba(255, 255, 255, 0.3); }
::-webkit-scrollbar-thumb:hover { background-color: rgba(255, 255, 255, 0.7); }

.modal-sys ::-webkit-scrollbar-track { background-color: rgba(0, 0, 0, 0.1); }
.modal-sys ::-webkit-scrollbar-thumb { background-color: rgba(0, 0, 0, 0.1); }
.modal-sys ::-webkit-scrollbar-thumb:hover { background-color: rgba(0, 0, 0, 0.1); }

/* The styles below are a hack to pre-load the fonts before the DOM is visible. */
.pre-render-fonts {
  position: absolute;
  top: 0;
  left: -600px;
  visibility: hidden;
}

.pre-render-fonts img,
.pre-render-fonts span svg { 
  width: 25px;
  height: 25px;
  display: block; 
}

.roboto-300 { 
  font-weight: var(--fnt-wgt-lgt);
  font-family: var(--fnt-roboto); 
}

.roboto-400 { 
  font-weight: var(--fnt-wgt-reg);
  font-family: var(--fnt-roboto); 
}

.roboto-500 { 
  font-weight: var(--fnt-wgt-med);
  font-family: var(--fnt-roboto); 
}

.roboto-700 { 
  font-weight: var(--fnt-wgt-bld);
  font-family: var(--fnt-roboto); 
}

.roboto-slab-300 { 
  font-weight: var(--fnt-wgt-lgt);
  font-family: var(--fnt-roboto-slab); 
}

.roboto-slab-400 { 
  font-weight: var(--fnt-wgt-reg);
  font-family: var(--fnt-roboto-slab); 
}




/*
 * Button & Text styles.
 */

body,
input,
textarea {
  color: var(--fnt-col-lgt);
  font-size: var(--fnt-sze-med);
  font-weight: var(--fnt-wgt-lgt);
  letter-spacing: initial;
  line-height: var(--lne-hgt-med);
  font-family: var(--fnt-roboto);
}

h1 {
  padding: 0;
  color: var(--fnt-col-lgt);
  font-size: 3rem;
  font-weight: var(--fnt-wgt-reg);
  line-height: 3.4rem;
  font-family: var(--fnt-roboto-slab);
}

h2 {
  color: var(--fnt-col-lgt);
  font-size: var(--fnt-sze-lrg);
  font-weight: var(--fnt-wgt-lgt);
  line-height: var(--lne-hgt-lrg);
  font-family: var(--fnt-roboto-slab);
}

h3 {
  color: var(--fnt-col-lgt);
  font-size: var(--fnt-sze-lrg);
  font-weight: var(--fnt-wgt-lgt);
  line-height: var(--lne-hgt-lrg);
  font-family: var(--fnt-roboto-slab);
}

b { font-weight: var(--fnt-wgt-reg); }

.fnt-lgt { color: var(--fnt-col-lgt); }

.txt-lnk {
  width: auto !important;
  color: var(--fnt-col-lgt);
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  text-decoration: underline;
}

.txt-lnk.reverse { color: var(--fnt-col-dim); }

.txt-lnk-highlight { color: var(--fnt-col-dim); }

.txt-lnk-highlight:hover {
  cursor: pointer;
  opacity: 1;
  color: var(--fnt-col-lgt);
}

.txt-lnk-underline { text-decoration: none; }

.txt-lnk-underline:hover {
  cursor: pointer;
  text-decoration: underline;
}

.txt-breadcrumb {
  padding-right: 15px;
  text-align: left;
  color: var(--fnt-col-lgt);
  font-size: var(--fnt-sze-mve);
  font-weight: var(--fnt-wgt-lgt);
  line-height: var(--lne-hgt-mve);
  font-family: var(--fnt-roboto-slab);
}

.txt-bld-red {
  color: var(--col-red);
  font-weight: var(--fnt-wgt-med);
  font-family: var(--fnt-roboto);
}

.txt-bld-gry {
  color: var(--col-gry);
  font-weight: var(--fnt-wgt-med);
  font-family: var(--fnt-roboto);
}

.btn-gry { background-color: var(--col-gry); }
.btn-grn { background-color: var(--col-grn); }
.btn-oge { background-color: var(--col-oge); }
.btn-red { background-color: var(--col-red); }
.btn-gry-drk { background-color: var(--col-gry-drk); }

.btn-dim { 
  position: relative; 
  overflow: hidden;
}

.btn-dim:after { 
  content: '';
  width: 100%;
  height: calc( 100% + 30px );
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--bkgrnd-mask-65);
}

.btn-pill {
  padding: 10px 20px;
  color: var(--fnt-col-lgt);
  font-weight: var(--fnt-wgt-reg);
  font-family: var(--fnt-roboto-slab);
  border-radius: var(--brdr-rad-8);
  border: var(--brdr-solid-wht);
}

.btn-box {
  padding: 10px 20px;
  position: relative;
  color: var(--fnt-col-lgt);
  font-weight: var(--fnt-wgt-reg);
  font-family: var(--fnt-roboto-slab);
  border-radius: 0;
  border: var(--brdr-solid-wht-4);
}

.btn-pill:hover,
.btn-box:hover { filter: brightness(110%) }

.btn-toggle { 
  transition: padding-left 0.2s ease-out, padding-right 0.2s ease-out;
  box-shadow: var(--box-shadow-med); 
}

.btn-toggle.selected { 
  padding-right: 35px;
  padding-left: 5px;
  box-shadow: var(--box-shadow-lgt); 
}

.btn-toggle .icon-tick {
  position: absolute;
  top: 12px;
  right: 14px;
  width: 22px;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.btn-toggle.selected .icon-tick { opacity: 1; }

.btn-toggle .icon-tick svg { 
  width: 100%;
  height: 100%;
  fill: var(--col-wht); 
}

.btn-exam,
.btn-demand-exam,
.btn-epsm-exam { 
  width: 100%;
  height: 100%;
  line-height: 100%;
  text-transform: capitalize;
  color: var(--fnt-col-lgt);
  font-weight: var(--fnt-wgt-reg);
  font-family: var(--fnt-roboto-slab);
  background-color: 'transparent'; 
}

.btn-epsm-exam { font-size: 85%; }

.exam-stick.taking .btn-exam.highlight,
.exam-stick.retaking .btn-exam.highlight,
.exam-stick.taking .btn-demand-exam.highlight,
.exam-stick.retaking .btn-demand-exam.highlight,
.exam-stick.taking .btn-epsm-exam.highlight,
.exam-stick.retaking .btn-epsm-exam.highlight { border: 2px solid var(--col-wht); }

.exam-stick.taking .btn-exam.highlight,
.exam-stick.retaking .btn-exam.highlight { height: 62%; }

.exam-stick.taking .btn-epsm-exam.highlight,
.exam-stick.retaking .btn-epsm-exam.highlight { background-color: var(--col-red) !important; }

.btn-book,
.btn-plan { 
  width: 100%;
  height: 100%;
  line-height: 100%;
  color: var(--fnt-col-lgt);
  font-weight: var(--fnt-wgt-reg);
  font-family: var(--fnt-roboto-slab);
  background-color: 'transparent'; 
}

.btn-exam:hover,
.btn-demand-exam:hover,
.btn-epsm-exam:hover,
.btn-book:hover,
.btn-plan:hover { filter: brightness(115%) }

.btn-cover-stick {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: block;
  background-color: transparent;
}

.dsk-mn-nav { opacity: 0; }
.dsk-mn-nav.show { opacity: 1; }

.dsk-mn-nav:after {
  content: '';
  margin: 10px auto 0 auto;
  display: block;
  width: 60%;
  height: 4px;
  background-color: var(--col-blk);
}

.mn-nav-btns button { 
  opacity: 0.4; 
  position: relative;
}

.mn-nav-btns button:focus,
.mn-nav-btns button:hover { opacity: 0.7; }

.mn-nav-btns button.selected { opacity: 1; }

.mn-nav-btns button.selected:after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: -17px;
  left: calc(50% - 5px);
  border-radius: 50%;
  background-color: var(--col-wht);
}

.mn-nav-btns button:first-child { margin-right: 30px; }

.mn-nav-btns button svg {
  margin: 0 auto 10px auto;
  display: block;
  height: 31px;
  fill: var(--col-wht);
}

.mn-nav-btns button span { 
  display: block; 
  font-size: 14px;
}

.icon-exam-planning,
.icon-study-planning {
  width: 50px;
}

.icon-exam-planning svg,
.icon-study-planning svg {
  margin: 0;
  display: block;
  width: 100%;
  height: auto;
  fill: var(--col-wht);
}

.btn-reset {
  position: absolute;
  top: 20px;
  left: 0;
  width: 100px;
  height: 60px;
  display: flex;
  flex-flow: row nowrap;
  z-index: 12;
}

.btn-reset > .content {
  display: inline-block;
  height: 100%;
  flex: 1;
  vertical-align: top;
  font-weight: var(--fnt-wgt-reg);
  font-family: var(--fnt-roboto-slab);
  background-color: var(--col-bkgrnd-chevron-2);
}

.btn-reset > button.content { background-color: var(--col-bkgrnd-chevron-2); }

.btn-reset > button.content span { display: block; }

.btn-reset > button.content span:last-child { padding-top: 2px; }

.btn-reset > button.content span svg { width: 18px; }

.btn-reset .chevron-img {
  margin-left: -1px;
  height: 100%;
  display: inline-block;
  vertical-align: top;
}

.btn-reset .chevron-img img { height: 100%; }

.btn-scrubber {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  outline: none;
  background: transparent;
  -webkit-appearance: none;
  appearance: none;
}

.btn-scrubber::-webkit-slider-thumb {
  width: 50px;
  height: 38px;
  cursor: pointer;
  border-radius: var(--brdr-rad-6);
  border: 1px solid var(--col-wht);
  background: var(--col-bkgrnd-drk-blu-6);
  -webkit-appearance: none;
  appearance: none;
}

.btn-scrubber::-moz-range-thumb {
  width: 50px;
  height: 38px;
  cursor: pointer;
  border-radius: var(--brdr-rad-6);
  border: 1px solid var(--col-wht);
  background: var(--col-bkgrnd-drk-blu-6);
}

.btn-choose-mood {
  width: 40px;
  height: 40px;
  position: relative;
}

.btn-choose-mood:not(:last-child) { margin-right: 20px; }

.btn-choose-mood svg,
.btn-choose-mood img {
  width: inherit;
  height: inherit;
}

.btn-choose-mood span {
  margin-top: 5px;
  position: absolute;
  bottom: -25px;
  left: 40%;
  display: inline-block;
  color: rgb(220, 220, 220);;
  font-size: var(--fnt-sze-sml);
  font-weight: var(--fnt-wgt-med);
  font-family: var(--fnt-roboto);
}

.btn-choose-mood.selected span { color: var(--col-red); }

.btn-choose-mood.selected:after {
  content: '';
  width: 112%;
  height: 112%;
  position: absolute;
  top: -6px;
  left: -6px;
  border-radius: 50%;
  border: 4px solid var(--col-red);
}

.txt-study-plane-tip {
  padding: 15px 20px 0 20px;
  min-height: 85px;
  text-align: center;
  color: var(--fnt-col-drk);
  font-size: var(--fnt-sze-med);
  font-weight: var(--fnt-wgt-lgt);
}

.btn-task-delete-wrapper {
  padding: 8px 2px 0 8px;
}

.btn-task-delete {
  padding: 4px;
  width: 30px;
  height: 30px;
  display: inline-block;
}

.btn-task-delete:after {
  content: '\63';
  color: var(--fnt-col-drk);
  font-size: 16px;
  font-family: var(--fnt-compass);
}

.btn-sml {
  padding: 5px 20px;
  color: var(--fnt-col-lgt);
  font-weight: var(--fnt-wgt-reg);
  font-family: var(--fnt-roboto-slab);
  border-radius: var(--brdr-rad-8);
  border: var(--brdr-solid-wht);
  box-shadow: var(--box-shadow-med);
}

.btn-confirm {
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  border-radius: var(--brdr-rad-8);
  border: 1px dashed rgba(255, 0, 0, 0.3);
  background-color: rgb(255, 255, 255, 0.6);
}

.btn-confirm.show { display: flex; }

.btn-confirm .btn-grp {
  padding: 6px 12px;
  color: var(--fnt-col-lgt);
  font-weight: var(--fnt-wgt-reg);
  font-family: var(--fnt-roboto-slab);
  border-radius: var(--brdr-rad-8);
  border: var(--brdr-solid-wht-2);
  background-color: var(--col-red);
  box-shadow: var(--box-shadow-med);
}

.btn-confirm .btn-grp span { opacity: 0.8; }

.btn-confirm .btn-grp button { padding: 0 10px; }

.btn-ghost {
  opacity: 0.1 !important;
  cursor: default;
  pointer-events: none;
}

.btn-session {
  margin-top: 15px;
  width: 190px;
  border: var(--brdr-solid-wht-4);
}

.btn-session:first-child { margin-right: 15px; }

.btn-disable {
  cursor: default;
  pointer-events: none;
}

.btn-done-tasks {
  padding: 10px 50px;
  border: var(--brdr-solid-wht-4);
}




 
 
 
 
/*
 * Layout styles.
 */
 
body { background-color: var(--col-bkgrnd-blu); }

.app-root,
.app-intro { 
  width: 100%;
  height: 100%;
  position: relative;
}

.app-intro { overflow: hidden; }

.app-intro .app-intro-brand-wrapper { 
  height: 100%; 
  overflow: hidden;
  opacity: 0;
  animation: intro-fade 2.8s 0.4s;
  animation-fill-mode: forwards;
}

.app-intro .app-intro-brand {
  padding-top: 0;
  width: 300px;
  height: 175px;
  text-align: center;
  animation: intro-zoom 1.8s 1.4s;
  animation-fill-mode: forwards;
  transform-origin: center 40%;
  transform: perspective(1px) translateZ(0);
}

.app-intro .app-intro-brand .quad {
  margin: 0 auto 20px auto;
  position: relative;
  width: 125px;
  height: 125px;
}

.app-intro .app-intro-brand .quad span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;
  font-size: 1.8rem;
  line-height: 125px;
  font-family: var(--fnt-roboto-slab);
  animation: intro-txt-fade 0.4s 2.5s;
  animation-fill-mode: forwards;
}

.app-intro .app-intro-brand .quad img {
  width: 100%;
  height: 100%;
}

.app-intro .app-intro-brand .strapline {
  font-size: var(--fnt-sze-mve);
  line-height: var(--lne-hgt-mve);
  font-family: var(--fnt-roboto-slab);
}

@keyframes intro-fade {
  0%    { opacity: 0; }
  15%    { opacity: 1; }
  85%   { opacity: 1; }
  100%  { opacity: 0; }
}

@keyframes intro-txt-fade {
  0%    { opacity: 1; }
  100%  { opacity: 0; }
}

@keyframes intro-zoom {
  50%   { transform: scale(1); }
  60%   { transform: scale(0.9); }
  100%  { transform: scale(12); }
}

.app-home { 
  height: 100%; 
  max-height: 100%; 
  opacity: 1;
  position: relative;
  z-index: 111;
  background: var(--col-bkgrnd-blu);
  background: radial-gradient(circle, rgba(38,61,83,1) 31%, rgba(20,32,43,1) 100%);
}

.app-home.transition { transition: opacity 0.3s ease-out; }

.app-home.hide { opacity: 0; }

.app-header .mn-toolbar { background-color: var(--col-blk); }

.app-header .mn-toolbar > div {
  margin: 0 auto;
  padding: 10px 5px;
  width: 95%;
  max-width: 1400px;
  background-color: var(--col-blk);
}

.app-header .mn-navbar { flex: 0 0 100px; } 

.app-header .mn-navbar .bkgrnd-col { background-color: var(--col-bkgrnd-chevron-1); }

.app-header .mn-navbar .col-lft,
.app-header .mn-navbar .col-rgt { flex: 1; }

.app-header .mn-navbar .col-mid { 
  max-width: 1400px;
  flex: 1 1 95%;  
}

.app-header .mn-navbar .col-mid .chevron:first-child { flex: 0 0 250px;  }

.app-header .mn-navbar .col-mid .chevron:last-child { flex: 1;  }

.app-header .mn-navbar .col-mid .chevron:last-child > .content { width: calc(100% - 20px);  }

.app-header .mn-navbar .chevron > .content { padding: 15px; }

.app-header .mn-navbar .chevron > .content,
.app-header .mn-navbar .chevron.ch-lft:before,
.app-header .mn-navbar .chevron.ch-rgt:after { 
  border-left: 1px solid var(--col-bkgrnd-chevron-1);
  border-right: 1px solid var(--col-bkgrnd-chevron-1);
  background-color: var(--col-bkgrnd-chevron-1); 
}

.app-header .mn-navbar .chevron:not(:first-child) { margin-left: -35px; }

.app-header .mn-navbar .chevron-img {
  margin-left: -1px;
  margin-right: -1px;
  height: 100px;
  display: inline-block;
  vertical-align: top;
}

.app-header .mn-navbar .chevron-img img { height: 100%; }

.app-header .mn-navbar .logo {
  padding-left: 5px;
  width: 240px;
  background-color: var(--col-bkgrnd-chevron-1); 
}

.app-header .mn-navbar .cta {
  flex: 1;
  background-color: var(--col-bkgrnd-chevron-1); 
}

.logo-wrapper {
  height: 60px;
  display: inline-block;
  cursor: pointer;
}

.logo-wrapper img { height: 100%; }

.app-body { 
  padding: 0;
  flex: 1 1 100%; 
  overflow: hidden;
}

.app-footer { 
  padding: 5px 0;
  user-select: none;
  flex: 0 0 20px; 
  font-size: var(--fnt-sze-tny);
}

.app-study-planner { height: 100%; }

.body-content {
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  max-width: 1440px;
  min-height: 0;
  flex: auto;
}

.mob-header {
  padding: 20px 0 0 0;
  display: none;
}

.study-breadcrumb { padding: 25px 0; }

.study-breadcrumb .btns-grp { padding-top: 10px; }

.study-planner {
  padding: 0 6%;
  flex: auto;
  min-height: 0;
  transition: padding 0.3s ease-out;
}

.study-planner .item-daily-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.study-planner .scroller-vrt {
  padding-left: 8px;
  direction: rtl;
}

.study-planner .scroller-vrt .item-daily-list { direction: ltr; }

.study-planner .item-daily-list .item-daily:not(:last-child) { margin-bottom: 10px; }

.study-planner .item-daily-list .item-daily {
  border-radius: var(--brdr-rad-6);
  border: 1px solid var(--col-wht);
  background: var(--col-bkgrnd-drk-blu);
}

.study-planner .item-lft {
  padding: 10px;
  width: 170px;
  cursor: default;
  pointer-events: none;
}

.study-planner .item-mid { width: 8px; }

.study-planner .item-rgt {
  padding: 10px;
  flex: 1;
  cursor: default;
  pointer-events: none;
}

.study-planner .item-lft.is-btn,
.study-planner .item-rgt.is-btn {
  cursor: pointer;
  pointer-events: initial;
}

.study-planner .daily-mood { width: 35px; }

.study-planner .daily-mood.pad-xtra { padding-top: 13px; }

.study-planner .daily-mood svg {
  width: inherit;
  height: inherit;
}

.study-planner .daily-task {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.study-planner .daily-task li.disabled { opacity: 0.4; }

.study-planner .daily-task li:not(:last-child) { padding: 0 0 8px 0; }

.btn-task-add,
.btn-task-check,
.btn-flag-check { 
  margin: 4px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-shadow: var(--box-shadow-med);
}

.btn-task-add { align-self: flex-end; }

.btn-milestone-txt {
  padding-left: 10px;
  max-width: 50%;
  cursor: pointer;
  line-height: 18px;
}

.btn-task-add svg,
.btn-task-check svg,
.btn-flag-check svg {
  width: 35px;
  height: 35px;
  fill: var(--col-wht); 
}

.study-planner .daily-task li svg {
  width: 25px;
  min-width: 25px;
  height: 25px;
  fill: var(--col-wht);
}

.btn-flag-check svg circle,
.study-planner .daily-task li.flag svg circle {
  fill: var(--col-red) !important;
  stroke: var(--col-wht) !important;
  stroke-width: 3px !important;
}

.btn-task-check svg circle,
.study-planner .daily-task li svg circle {
  fill: var(--col-grn) !important;
  stroke: var(--col-wht) !important;
  stroke-width: 3px !important;
}

.btn-task-add svg path { fill: var(--col-red) !important; }

.study-planner .daily-task li span {
  padding: 3px 0 0 10px;
  display: inline-block;
}

.study-planner .daily-task li .icon-unchecked { 
  padding-left: 0; 
  width: 25px;
  height: 25px;
  opacity: 0.1;
}

.btn-task-check.unchecked svg circle,
.btn-flag-check.unchecked svg circle { fill: var(--col-gry) !important; }

.btn-task-check.unchecked svg path,
.btn-flag-check.unchecked svg path { fill: var(--col-wht); }

.study-planner .daily-task li .icon-unchecked svg circle { fill: var(--col-wht) !important; }

.study-planner .daily-task li .icon-unchecked svg path { fill: var(--col-bkgrnd-drk-blu); }

.icon-planner-plus {
  width: 35px;
  height: 35px;
  display: inline-block;
}

.icon-planner-plus svg {
  width: inherit;
  height: inherit;
  fill: var(--col-wht);
  opacity: 0.1;
}

.study-scrubber { 
  padding: 15px 6%; 
  position: relative;
  transition: padding 0.3s ease-out;
}

.scrubber-btn-track { 
  height: 100%; 
  position: relative;
}

.scrubber { 
  padding: 1px; 
  height: 30px; 
  position: relative;
  background: var(--col-wht);
}

.scrubber span {
  /* min-width: 10px; */
  height: 100%;
  flex: auto;
  display: inline-block;
}

.scrubber.gap span:not(:last-child) { margin-right: 1px; } 

.planner-wrapper { height: 100%; }

.planner { 
  height: 100%;
  flex: 1 1 100%; 
}

.mob-planner-wrapper { 
  padding: 15px; 
  flex: 1 1 100%;
}

.mobile-intro > .content {
  padding: 0;
  width: 100vw; 
  min-width: 100vw;
  height: 100%;
  position: relative;
}

.mobile-intro .mobile-logo-wrapper { padding: 20px; }

.mobile-intro .mobile-logo { height: 60px; }

.mobile-intro .mobile-logo img {
  width: auto;
  height: 100% !important;
}

.mobile-logo span {
  margin-left: 15px;
  font-size: var(--fnt-sze-hge);
  font-family: var(--fnt-roboto-slab);
}

.mobile-intro .start-chevron {
  margin-top: 20vw;
  margin-left: 0;
  width: 90%;
  height: 90px;
}

.mobile-intro .start-chevron .start-txt {
  margin-right: -1px;
  padding: 16px 20px;
  width: calc(100% - 25px);
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  font-size: 2.3rem;
  line-height: 2.8rem;
  background-color: var(--col-bkgrnd-chevron-2);
}

.mobile-intro .start-chevron:after { width: 20px; }

.mobile-help-txt { padding: 0 0 30px 0; }

.mobile-help-txt .help-txt { padding: 0 15px 20px 15px; }

.mobile-help-txt .help-txt:before {
  content: "!";
  margin-top: 4px;
  margin-right: 15px;
  width: 30px;
  min-width: 30px;
  height: 30px;
  display: block;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  font-family: var(--fnt-roboto);
  border-radius: var(--brdr-rad-4);
  border: var(--brdr-solid-wht);
}

.mobile-copyright-txt {
  padding: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: var(--fnt-sze-tny);
}

.mobile-action { position: relative; }

.mobile-action > .content {
  padding: 15px;
  width: 100vw; 
  min-width: 100vw;
  height: 100%;
}

.mobile-action .snapshot-txt {
  margin: 0 auto;
  padding-top: 35vh;
  opacity: 0.5;
  width: 70%;
  text-align: center;
}

.mobile-action .snapshot-txt.enable { opacity: 1; }

.mob-login,
.mob-snapshot { padding: 30px 15px; }

.mob-login span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mob-login button {
  margin-left: 15px;
  flex-shrink: 0;
  text-decoration: underline;
}

.mob-login button:focus,
.mob-login button:hover { color: var(--fnt-col-lgt);  }

.planner > .content {
  position: relative;
  width: 100%;
  max-width: 1400px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

/* .mobile-action .btn-snapshot-wrapper {
  padding: 20px;
  text-align: center;
} */

/* Custom modal styles, system modal then instructional modal */
.el-dialog__wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.modal-cus { 
  margin: 0;
  top: 0 !important;
  left: 0;
  opacity: 1;
  position: relative;
  transform: initial;
  max-width: 500px;
  border-radius: var(--brdr-rad-8); 
}

.dialog-fade-leave-active .modal-ins {
  border-color: transparent;
  border-style: none;
}

.modal-cus .el-dialog__header { display: none; }
.modal-cus .el-dialog__body { padding: 30px; }

.modal-sys {
  color: var(--fnt-col-drk);
  background-color: var(--col-wht);
}

.modal-sys h4 { 
  text-align: center;
  color: var(--fnt-col-drk);
  font-size: var(--fnt-sze-mve);
  line-height: var(--lne-hgt-mve);
  font-weight: var(--fnt-wgt-reg);
  font-family: var(--fnt-roboto-slab);
}

.modal-sys h5 { 
  padding-bottom: 5px;
  text-align: center;
  text-transform: uppercase;
  color: var(--fnt-col-mid);
  font-size: var(--fnt-sze-sml);
  font-weight: var(--fnt-wgt-med);
}

.modal-sys h6 {
  padding-bottom: 20px; 
  text-align: center;
  color: var(--fnt-col-drk);
  font-size: var(--fnt-sze-sml);
  font-weight: var(--fnt-wgt-lgt);
}

.modal-sys .modal-body { padding-top: 20px; }

.modal-sys .txt-no-time {
  margin: 0 auto;
  text-align: center;
  width: 80%;
  color: var(--col-red);
  font-weight: var(--fnt-wgt-reg);
}

.modal-sys .modal-btns-grid {
  margin: 0 auto;
  max-width: 80%;
  max-height: 130px;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 15px;
}

.modal-sys .modal-btns-grid button { width: 100%; }

.modal-sys .modal-btns-wrapper { 
  margin: 0 auto;
  max-width: 80%;
}

.modal-sys .modal-btns-wrapper.cntr { text-align: center; }

.modal-sys .modal-btns-wrapper button { padding: 10px 40px; }

.modal-sys .modal-footer {
  padding-top: 30px;
  text-align: center;
  font-size: var(--fnt-sze-tny);
  line-height: var(--lne-hgt-sml);
}

.modal-ins {
  color: var(--fnt-col-lgt);
  border: var(--brdr-solid-wht-2);
  background-color: var(--col-bkgrnd-drk-blu);
}

.modal-ins .modal-body { 
  margin: 0 auto;
  width: 90%; 
}

.modal-ins .quad-wrapper {
  width: 100px;
  height: 100px;
  position: relative;
}

.modal-ins .quad-wrapper img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.modal-ins .quad-wrapper span {
  display: block;
  text-align: center;
  color: var(--fnt-col-lgt);
  font-size: 15px;
  line-height: 100px;
  font-family: var(--fnt-roboto-slab);
}

.modal-ins h1 { 
  padding: 30px 0; 
  text-align: center;
}

.modal-ins h2 { 
  padding: 0 0 20px 0; 
  text-align: center;
  color: var(--fnt-col-lgt);
}

.modal-sys h2 { 
  padding: 0 0 20px 0; 
  text-align: center;
  color: var(--fnt-col-drk);
  font-weight: var(--fnt-wgt-med);
}

.modal-ins h4 {
  padding: 0 0 20px 0; 
  text-align: center;
  color: var(--fnt-col-lgt);
  font-size: 2.4rem;
  font-weight: var(--fnt-wgt-lgt);
  line-height: 2.6rem;
  font-family: var(--fnt-roboto-slab);
}

.modal-ins .list-header {
  padding: 0 0 24px 0; 
  text-align: center;
  opacity: 0.55;
  color: var(--fnt-col-lgt);
}

.modal-ins .list-item {
  padding: 0 0 12px 0; 
  text-align: center;
  color: var(--fnt-col-lgt);
  font-size: var(--fnt-sze-lrg);
  font-weight: var(--fnt-wgt-lgt);
  line-height: 22px;
  font-family: var(--fnt-roboto-slab);
}

.modal-ins .period-list { margin-bottom: 20px; }

.modal-ins .period-list div { 
  margin-bottom: 4px;
  padding: 6px 15px;
  text-align: center;
  color: var(--fnt-col-lgt);
  font-weight: var(--fnt-wgt-reg);
  font-family: var(--fnt-roboto-slab);
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}

.modal-ins .cta-btns { padding: 15px 0 10px 0; }

.modal-ins .cta-btns button { padding: 10px 40px; }

.modal-ins .modal-footer {
  padding-top: 30px;
  text-align: center;
  color: var(--fnt-col-dim);
  font-size: var(--fnt-sze-tny);
  line-height: var(--lne-hgt-sml);
}

.modal-cookie-txt {
  padding: 10px 0;
  text-align: center;
  color: var(--fnt-col-lgt);
  font-size: var(--fnt-sze-tny);
  line-height: var(--lne-hgt-sml);
}

.modal-cus .choose-task { max-height: 60vh; }

.modal-cus .choose-task .tasks-control { 
  flex: 1; 
  position: relative;
  overflow-y: auto;
  border-top: var(--brdr-solid-gryish);
  border-bottom: var(--brdr-solid-gryish);
}

.modal-title-para {
  padding: 10px 0 0 0;
  display: block;
}

.exams {
  margin: 0 auto;
  width: calc(100% - 180px);
  position: relative;
  z-index: 10;
  overflow-x: auto;
}

.exams > .content { min-width: 1210px; }

.exams-header {
  padding: 20px 0 15px 0;
  flex: 0 0 60px;
  width: 100%;
}

.exams-header > .content { 
  width: 100%;
  height: 60px; 
  position: relative;
  font-family: var(--fnt-roboto-slab);
}

.exams-body {
  margin: 0 20px;
  flex: 1;
  width: calc(100% - 50px);
  min-height: 556px;
}

.exams-group:not(:last-child) { margin-right: 20px; }

.exams-group:nth-child(1) { width: calc(100% / 16 * 3); }
.exams-group:nth-child(2) { width: calc(100% / 16 * 6); }
.exams-group:nth-child(3) { width: calc(100% / 16 * 1); }
.exams-group:nth-child(4) { width: calc(100% / 16 * 2); }
.exams-group:nth-child(5) { width: calc(100% / 16 * 4); }

.exam-stick {
  margin-right: 6px;
  flex: 1;
  max-height: 600px;
  overflow: hidden;
  display: inline-block;
  border: var(--brdr-solid-wht);
  border-radius: var(--brdr-rad-6);
  background-color: var(--col-bkgrnd-drk-blu-9);
}

.exam-stick > .content { position: relative; }

.exam-stick.exempt,
.exam-stick.passed { max-height: 84px; }

.exam-stick:last-child { margin-right: 0; }

.app-home:not(.intro) .exams-group .exam-stick.completed { max-height: 84px; }

.exam-header { 
  height: 90px; 
  position: relative;
}

.exam-header button { 
  width: 100%;
  height: 100%; 
  display: block;
}

.exam-header .exam-status {
  padding-top: 4px;
  height: 40%;
  position: relative;
  background-color: transparent;
  transition: background-color 0.3s ease-out;
}

.exam-header .exam-status .text { 
  height: 100%;
  font-size: var(--fnt-sze-sml); 
  text-transform: capitalize;
  font-family: var(--fnt-roboto-slab);
}

.exam-header .exam-status .dots {
  position: absolute;
  bottom: -1px;
  width: 100%;
  opacity: 0.6;
}

.exam-header .exam-status .dots .icon-dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
  background-color: var(--col-wht);
}

.exam-header .exam-status .dots .icon-dot:nth-child(2) { margin: 0 3px; }

.exam-header .exam-title {
  padding-top: 4px;
  height: 60%;
  position: relative;
  font-size: var(--fnt-sze-hge);
  font-family: var(--fnt-roboto-slab);
}

.exam-stick.completed .exam-header .exam-title .icon-tick,
.exam-header.tick .exam-title .icon-tick {
  position: absolute;
  top: 21px;
  right: 3px;
  width: 20px;
  display: none;
}

.exam-stick.completed .exam-header .exam-title .icon-tick,
.exam-stick.exempt .exam-header.tick .exam-title .icon-tick,
.exam-stick.passed .exam-header.tick .exam-title .icon-tick { display: block; }

.exam-stick.completed .exam-header .exam-title .icon-tick img,
.exam-header.tick .exam-title .icon-tick img {
  width: 100%;
  height: 100%;
}

.exam-cell {
  margin-bottom: 2px;
  height: 34px;
  position: relative;
}

.exam-cell > .content {
  height: 40px;
  background-color: var(--col-red);
}

.exam-cell .timeline {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.exam-cell .timeline.disabled {
  pointer-events: none;
  filter: brightness(0.4);
  transition: all 0.2s ease-out 0.3s;
}

.exam-cell .timeline.hide { display: none; }

.exam-cell .timeline-placer {
  position: relative;
  width: 100%;
}

.exam-cell .timeline-bars {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.exam-cell .timeline .study-bar { border-top: var(--brdr-solid-wht-2); }

.exam-cell .timeline .study-bar:first-child { 
  padding-top: 1px;
  border-top: var(--brdr-solid-wht-2); 
}

.exam-footer {
  height: 32px;
  overflow: hidden;
  border-bottom-left-radius: var(--brdr-rad-6); 
  border-bottom-right-radius: var(--brdr-rad-6);
}

.exam-body,
.exam-footer { 
  cursor: default;
  pointer-events: none;
  position: relative;
}

.exam-body .bkgrnd-mask,
.exam-footer .bkgrnd-mask { 
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: var(--bkgrnd-mask-80);
}

.exam-stick.taking .exam-body,
.exam-stick.retaking .exam-body,
.exam-stick.taking .exam-footer,
.exam-stick.retaking .exam-footer {
  cursor: pointer;
  pointer-events: auto;
}

.exam-stick.taking .exam-body.disabled,
.exam-stick.retaking .exam-body.disabled,
.exam-stick.taking .exam-footer.disabled,
.exam-stick.retaking .exam-footer.disabled { cursor: default; }

.exam-stick.taking .exam-body .bkgrnd-mask,
.exam-stick.retaking .exam-body .bkgrnd-mask,
.exam-stick.taking .exam-footer .bkgrnd-mask,
.exam-stick.retaking .exam-footer .bkgrnd-mask {
  pointer-events: none;
  background-image: none;
}

.exam-stick.taking .exam-body.highlight-all .exam-cell button,
.exam-stick.retaking .exam-body.highlight-all .exam-cell button {
  border: 2px solid var(--col-wht);
}

.exams-footer {
  padding: 15px 0 5px 0;
  flex: 0 0 40px;
  width: 100%;
  height: 90px;
}

.exams-footer > .content { height: 100%; }

.exams-footer .chevron > .content,
.exams-footer .chevron.ch-lft:before,
.exams-footer .chevron.ch-rgt:after { 
  border-left: 1px solid var(--col-bkgrnd-chevron-3);
  border-right: 1px solid var(--col-bkgrnd-chevron-3);
  background-color: var(--col-bkgrnd-chevron-3); 
}

.exams-footer > .content .chevron:first-child { width: calc(100% / 16 * 9 - 15px); }
.exams-footer > .content .chevron:last-child { width: calc(100% / 16 * 7 + 15px); }

.exams-footer .exams-footer-chevron {
  height: 100%;
  background-color: var(--col-bkgrnd-chevron-3);
}

.exams-footer .exams-footer-chevron > .content { height: 100%; }

.exams-footer .exams-footer-chevron > .content .help-txt { 
  height: 100%; 
  align-items: center;
}

.exams-footer > .content .exams-footer-chevron.info-1 { width: calc(100% / 16 * 9 - 15px); }
.exams-footer > .content .exams-footer-chevron.info-2 { 
  margin-left: -2px;
  width: calc(100% / 16 * 7 + 15px); 
}

.exams-footer .chevron-img,
.exams-footer .chevron-img img { height: 100%; }

.exams-footer .help-txt {
  padding: 0 15px;
  font-size: var(--fnt-sze-sml);
  line-height: var(--lne-hgt-sml);
}

.exams-footer .help-txt .info-icon {
  margin-right: 15px;
  width: 30px;
  min-width: 30px;
  height: 30px;
  display: inline-block;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  font-family: var(--fnt-roboto);
  border-radius: var(--brdr-rad-4);
  border: var(--brdr-solid-wht);
}

.exams-footer .help-txt .info-txt { display: inline-block; }

.calendar {
  position: absolute;
  top: 186px;
  left: 0;
  width: 100%;
  z-index: 0;
}

.calendar .row {
  margin-bottom: 2px;
  padding: 0 10px;
  height: 34px;
  line-height: 34px;
  background-color: var(--col-bkgrnd-wht-05);
}

.calendar .row:last-child { margin-bottom: 0; }

.calendar-sticky-wrapper {
  width: 100%;
  height: 100%;
  z-index: 20;
  position: absolute;
  pointer-events: none;
}

.calendar-sticky {
  position: sticky;
  position: -webkit-sticky; /* Safari */
  bottom: 0;
  left: 0;
  width: 90px;
  /* width: 115px; */
  height: 100%;
  z-index: 20;
  display: inline-block;
}

.exams-header .chevron-img {
  margin-left: -1px;
  margin-right: -1px;
  width: auto;
  height: 100%;
  display: inline-block;
  vertical-align: top;
}

.exams-header .chevron-img img { height: 100%; }

.exam-section-title {
  height: 100%;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}

.exam-section-title.disabled { 
  cursor: default; 
  pointer-events: none;
}

.exam-section-title > .content { 
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  background-color: var(--col-bkgrnd-chevron-2); 
}

.exam-section-title.title-1 { width: 220px; }
.exam-section-title.title-2 { width: 410px; }
.exam-section-title.title-3 { width: 70px; }
.exam-section-title.title-4 { width: 440px; }

.exam-section-title.title-3 > .content { padding-left: 10px; }

.exam-section-title > .content div { 
  padding-left: 12px; 
  display: inline-block;
}

.exam-section-title.title-3 > .content div { padding-left: 0; }

.period-selector,
.period-chart,
.period-cta {
  margin: 0 auto 20px auto;
  width: 75%;
}

.period-cta { margin-bottom: 0; }

.period-selector button {
  padding: 0;
  width: 50px;
  height: 50px;
  position: relative;
  line-height: 0;
}

.period-selector .btn-minus span {
  position: absolute;
  top: 18px;
  left: 13px;
  font-size: 44px;
}

.period-selector .btn-plus span {
  position: absolute;
  top: 20px;
  left: 12px;
  font-size: 30px;
}

.period-selector .duration {
  padding: 0 15px;
  font-size: 4.0rem;
  line-height: 4.8rem;
  font-family: var(--fnt-roboto-slab);
}

.period-chart div {
  margin-bottom: 4px;
  padding: 6px 15px;
  color: var(--fnt-col-lgt);
  font-weight: var(--fnt-wgt-reg);
  font-family: var(--fnt-roboto-slab);
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}
.period-chart div:last-child { margin-bottom: 0; }

.period-cta button { width: 130px; }

.modal-checkboxes {
  margin: 15px auto;
  width: 80%;
}

.cus-chkbox {
  margin-bottom: 12px;
  padding-left: 45px;
  height: 30px;
  position: relative;
  display: block;
  cursor: pointer;
}

.cus-chkbox:last-child { margin-bottom: 0; }

.cus-chkbox input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  cursor: pointer;
}

.cus-chkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  border-radius: var(--brdr-rad-4);
  background-color: var(--col-wht);
}

.cus-chkbox .checkmark .icon-tick {
  position: absolute;
  top: 7px;
  left: 4px;
  width: 22px;
  opacity: 0;
  display: inline-block;
  transition: opacity 0.3s ease-out;
}

.cus-chkbox .checkmark .icon-tick svg { 
  width: 100%;
  height: 100%;
  fill: var(--col-bkgrnd-drk-blu); 
}

.cus-chkbox input:checked ~ .checkmark .icon-tick { opacity: 1; }

.cus-chkbox .label {
  padding-top: 3px;
  display: inline-block;
  color: var(--fnt-col-lgt);
  font-size: var(--fnt-sze-lrg);
  font-weight: var(--fnt-wgt-lgt);
  line-height: var(--lne-hgt-lrg);
  font-family: var(--fnt-roboto-slab);
}

.screenshot-mobile-header {
  width: 100%;
  height: 100px;
}

.screenshot-mobile-header .logo {
  padding: 20px;
  width: 300px;
  display: inline-block;
  background-color: var(--col-bkgrnd-chevron-1);
  background: -moz-linear-gradient(left,  rgba(21,35,48,0) 0%, rgba(21,35,48,1) 22%, rgba(21,35,48,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  rgba(21,35,48,0) 0%,rgba(21,35,48,1) 22%,rgba(21,35,48,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  rgba(21,35,48,0) 0%,rgba(21,35,48,1) 22%,rgba(21,35,48,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.screenshot-mobile-header .logo img {
  width: auto;
  height: 60px;
}

.screenshot-mobile-header .img-chevron {
  width: 30px;
  height: 100px;
  display: inline-block;
}

.screenshot-mobile-header .bar {
  flex: 1;
  display: inline-block;
  background-color: var(--col-bkgrnd-chevron-1);
  background: -moz-linear-gradient(left,  rgba(21,35,48,1) 0%, rgba(21,35,48,1) 80%, rgba(21,35,48,0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  rgba(21,35,48,1) 0%,rgba(21,35,48,1) 80%,rgba(21,35,48,0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  rgba(21,35,48,1) 0%,rgba(21,35,48,1) 80%,rgba(21,35,48,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.screenshot-mobile-mask {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 999;
  background: var(--col-bkgrnd-blu);
  background: radial-gradient(circle, rgba(38,61,83,1) 31%, rgba(20,32,43,1) 100%);
}

.screenshot-mobile-mask .lds-ring { margin-bottom: 15px; }

.screenshot-mobile-mask > div { text-align: center; }

.app-home .app-header .mn-navbar .dsk-mn-nav { transition: opacity 0.3s ease-out 0.6s; }
.app-home .exam-section-title h3 { transition: opacity 0.3s ease-out; }
.app-home .exam-section-title p { transition: opacity 0.3s ease-out 0.1s; }
.app-home .calendar .row:nth-child(1) > div { transition: opacity 0.3s ease-out 0.2s; }
.app-home .calendar .row:nth-child(2) > div { transition: opacity 0.3s ease-out 0.3s; }
.app-home .calendar .row:nth-child(3) > div { transition: opacity 0.3s ease-out 0.4s; }
.app-home .calendar .row:nth-child(4) > div { transition: opacity 0.3s ease-out 0.5s; }
.app-home .calendar .row:nth-child(5) > div { transition: opacity 0.3s ease-out 0.6s; }
.app-home .calendar .row:nth-child(6) > div { transition: opacity 0.3s ease-out 0.7s; }
.app-home .calendar .row:nth-child(7) > div { transition: opacity 0.3s ease-out 0.8s; }
.app-home .calendar .row:nth-child(8) > div { transition: opacity 0.3s ease-out 0.9s; }
.app-home .calendar .row:nth-child(9) > div { transition: opacity 0.3s ease-out 1.0s; }
.app-home .calendar .row:nth-child(10) > div { transition: opacity 0.3s ease-out 1.1s; }
.app-home .calendar .row:nth-child(11) > div { transition: opacity 0.3s ease-out 1.2s; }
.app-home .calendar .row:nth-child(12) > div { transition: opacity 0.3s ease-out 1.3s; }
.app-home .exams-body .exams-group:nth-child(1) .exam-stick:nth-child(1) > .content { transition: opacity 0.3s ease-out 0.1s; }
.app-home .exams-body .exams-group:nth-child(1) .exam-stick:nth-child(2) > .content { transition: opacity 0.3s ease-out 0.15s; }
.app-home .exams-body .exams-group:nth-child(1) .exam-stick:nth-child(3) > .content { transition: opacity 0.3s ease-out 0.2s; }
.app-home .exams-body .exams-group:nth-child(2) .exam-stick:nth-child(1) > .content { transition: opacity 0.3s ease-out 0.25s; }
.app-home .exams-body .exams-group:nth-child(2) .exam-stick:nth-child(2) > .content { transition: opacity 0.3s ease-out 0.3s; }
.app-home .exams-body .exams-group:nth-child(2) .exam-stick:nth-child(3) > .content { transition: opacity 0.3s ease-out 0.35s; }
.app-home .exams-body .exams-group:nth-child(2) .exam-stick:nth-child(4) > .content { transition: opacity 0.3s ease-out 0.4s; }
.app-home .exams-body .exams-group:nth-child(2) .exam-stick:nth-child(5) > .content { transition: opacity 0.3s ease-out 0.45s; }
.app-home .exams-body .exams-group:nth-child(2) .exam-stick:nth-child(6) > .content { transition: opacity 0.3s ease-out 0.5s; }
.app-home .exams-body .exams-group:nth-child(3) .exam-stick:nth-child(1) > .content { transition: opacity 0.3s ease-out 0.55s; }
.app-home .exams-body .exams-group:nth-child(4) .exam-stick:nth-child(1) > .content { transition: opacity 0.3s ease-out 0.6s; }
.app-home .exams-body .exams-group:nth-child(4) .exam-stick:nth-child(2) > .content { transition: opacity 0.3s ease-out 0.65s; }
.app-home .exams-body .exams-group:nth-child(5) .exam-stick:nth-child(1) > .content { transition: opacity 0.3s ease-out 0.7s; }
.app-home .exams-body .exams-group:nth-child(5) .exam-stick:nth-child(2) > .content { transition: opacity 0.3s ease-out 0.75s; }
.app-home .exams-body .exams-group:nth-child(5) .exam-stick:nth-child(3) > .content { transition: opacity 0.3s ease-out 0.8s; }
.app-home .exams-body .exams-group:nth-child(5) .exam-stick:nth-child(4) > .content { transition: opacity 0.3s ease-out 0.85s; }
.app-home .exams-footer .help-txt { transition: opacity 0.3s ease-out 0.5s; }

.app-home.intro .exam-section-title h3,
.app-home.intro .exam-section-title p,
.app-home.intro .calendar .row > div,
.app-home.intro .exams-body .exam-stick > .content,
.app-home.intro .exams-footer .help-txt {
  opacity: 0;
  transition: opacity 0.3s ease-out 0.8s;
}

.app-home.intro .btn-reset span {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.app-home .exams-body .exam-stick.has-transition { transition: opacity 0.3s ease-out 0.3s, background-color 0.3s ease-out 0.3s, max-height 0.3s ease-out; }

.app-home.intro .exams-body .exam-stick { 
  opacity: 0.3;
  background-color: transparent; 
  transition: opacity 0.3s ease-out, background-color 0.3s ease-out;
}

.mood-scroller {
  max-height: 50vh;
  overflow-y: auto;
}

.mood-scroller h2 { font-size: 1.6rem; }

.mood-wrapper {
  margin: 0 auto 25px auto;
  padding: 6px 36px;
  width: 250px;
  border-radius: 50px;
  background: var(--col-gry-lgt);
}

.tasks-wrapper { padding: 10px 5px 5px 5px; }

.tasks-wrapper .header {
  padding: 5px 0 10px 3px;
  color: var(--fnt-col-mid);
  font-size: var(--fnt-sze-sml);
}

.tasks-wrapper .list .task-item { width: 100%; }

.tasks-wrapper .list .task-item.disabled { 
  opacity: 0.5;
  pointer-events: none; 
}

.tasks-wrapper .cus-editable-textarea { 
  margin: 4px;
  position: relative;
  flex: auto; 
}

.milestone-flag {
  position: relative;
  z-index: 0;
}

.milestone-flag button,
.milestone-flag span { display: inline-block; }

.milestone-flag span { padding-left: 10px; }

.error-box {
  width: 100%;
  z-index: 1111;
  top: -500px;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transition: top 0.5s ease-out, opacity 0.5s ease-out;
}

.error-box.show { 
  top: 0; 
  opacity: 1;
}

.error-msg {
  margin: 10px auto;
  padding: 12px;
  max-width: 50%;
  border-radius: var(--brdr-rad-6);
  border: var(--brdr-solid-wht);
  background-color: rgba(250, 0, 0, 0.9);;
}

.error-pg-code {
  text-align: center;
  font-size: 10em;
  line-height: 1em;
}

.error-pg-txt { text-align: center; }

.error-pg-txt span { display: inline; }





/*
 * Custom form styles.
 */
 
.cus-selector {
  padding-bottom: 15px;
  width: 100%;
}

.cus-selector .sel-static {
  width:100%;
}

.cus-selector .sel-static button {
  margin: 0 3px;
  padding: 10px 15px;
  width: calc(100% - 6px);
  position: relative;
  text-align: left;
  color: var(--fnt-col-lgt);
  font-size: var(--fnt-sze-lrg);
  line-height: var(--lne-hgt-lrg);
  font-weight: var(--fnt-wgt-reg);
  font-family: var(--fnt-roboto-slab);
  border-radius: var(--brdr-rad-8);
  border: var(--brdr-solid-wht-2);
  box-shadow: var(--box-shadow-lgt);
}

.cus-selector .sel-static button:after {
  content: '\65';
  position: absolute;
  top: 12px;
  right: 15px;
  color: var(--fnt-col-lgt);
  font-size: var(--fnt-sze-lrg);
  font-family: var(--fnt-compass);
}

.cus-selector.show .sel-static button:after { content: '\68'; }

.cus-selector .sel-dynamic {
  margin-top: 2px;
  height: 148px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.cus-selector.show .sel-dynamic { max-height: 148px; }

.scroller-wrapper {
  margin: 4px;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  overflow: hidden;
  border-radius: var(--brdr-rad-8);
  border: var(--brdr-solid-gry);
  background: var(--col-wht);
  box-shadow: var(--box-shadow-lgt);
}

.cus-selector .sel-dynamic .scroller {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.cus-selector .sel-dynamic .scroller ul {
  margin: 0;
  padding: 5px 0 5px 0;
  list-style-type: none;
}

.cus-selector .sel-dynamic .scroller ul li button {
  padding: 4px 15px;
  display: block;
  width: 100%;
  text-align: left;
  color: var(--fnt-col-dim);
  font-size: var(--fnt-sze-lrg);
  line-height: var(--lne-hgt-lrg);
  font-weight: var(--fnt-wgt-reg);
  font-family: var(--fnt-roboto-slab);
}

.cus-selector .sel-dynamic .scroller ul li button:focus,
.cus-selector .sel-dynamic .scroller ul li button:hover { color: var(--fnt-col-drkish); }

.cus-textarea {
  margin-left: 48px;
  padding: 0 5px;
  width: 100%;
  height: 100px;
  position: relative;
}

.cus-textarea textarea {
  padding: 6px;
  width: 100%;
  height: 100%;
  resize: none;
  color: var(--fnt-col-drk);
  border-radius: var(--brdr-rad-8);
  border: var(--brdr-dashed-gry);
}

.cus-textarea .charCount {
  position: absolute;
  bottom: 0;
  right: 12px;
  color: var(--fnt-col-mid);
  font-size: 1rem;
}

.cus-editable-textarea textarea {
  padding: 8px 6px;
  width: 100%;
  display: block;
  resize: none;
  overflow-y: hidden;
  color: var(--fnt-col-drk);
  border-radius: var(--brdr-rad-8);
  border: var(--brdr-solid-gryish);
}

.cus-editable-textarea textarea:focus { 
  border: var(--brdr-dashed-gry); 
  box-shadow: var(--box-shadow-lgt);
}








/*
 * Utility styles.
 */
 
.is-mobile { display: none; }
.is-mobile.is-inline { display: none; }
.is-desktop { display: block; }
.is-desktop.is-inline { display: inline; }

.fixed-width {
  margin: 0 auto;
  padding: 0 25px;
  position: relative;
  width: 100%;
  max-width: 1024px;
}

.full-hgt { height: 100%; }

.flex-wrapper-col {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  align-content: flex-start;
  justify-content: flex-start;
}

.flex-wrapper-row {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  align-content: flex-start;
  justify-content: flex-start;
}

.flex-wrapper-center {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.flex-wrapper-center > * { align-self: center; }

.flex-justify-center { justify-content: center; }
.flex-justify-end { justify-content: flex-end; }

.flex-align-end { align-items: flex-end; }
.flex-align-center { align-items: center; }

.flex-space-between { justify-content: space-between; }

.flex-auto { flex: auto; }

.flex-self-start { align-self: flex-start; }
.flex-self-center { align-self: center; }
.flex-self-end { align-self: flex-end; }

.flex-wrap-ok { flex-wrap: wrap; }

.set-inline { display: inline; }

.algn-mid { vertical-align: middle; }

.spin-360 {
  display: inline-block;
  animation-name: spin-360;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform-origin: center center;
}

.spinner { display: none; }

.spinner-plan .spinner { display: block; }

.spinner-plan {
  width: 100%;
  height: 100%;
  position: relative;
  opacity: 0.4;
  background-color: var(--col-red);
}

.spinner-plan .spinner {
  position: absolute;
  top: calc(50% - 11px);
  left: calc(50% - 9px);
}

.spinner span,
.spinner-plan .spinner span {
  margin-top: 2px;
  width: 18px;
  height: 18px;
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  animation: spin-360 0.8s linear infinite;
}

.spinner span:before,
.spinner-plan .spinner span:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid #FFF;
  animation: prixClipFix 2.2s linear infinite;
  animation-direction: alternate-reverse;
}

.fetching .spinner {
  margin-right: 28px;
  position: relative;
  display: inline;
}

@keyframes spin-360 {
  from  { transform: rotate(0deg); }
  to    { transform: rotate(360deg); }
}

@keyframes prixClipFix {
  0%    {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
  25%   {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
  50%   {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
  75%   {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
  100%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}

.txt-pipe {
  width: 1px;
  background-color: var(--fnt-col-lgt);
}

.border-4 { border: var(--brdr-solid-wht-4) !important; }

.stage-none,
.stage-reflection,
.bkgrnd-gry { background-color: var(--col-gry); }

.stage-planning,
.bkgrnd-grn { background-color: var(--col-grn); }

.stage-learning,
.bkgrnd-yel { background-color: var(--col-yel); }

.stage-revision,
.bkgrnd-oge { background-color: var(--col-oge); }

.stage-final,
.stage-exam,
.bkgrnd-red { background-color: var(--col-red); }

.mgn-top-10   { margin-top: 10px !important; }
.mgn-top-15   { margin-top: 15px !important; }
.mgn-top-20   { margin-top: 20px !important; }
.mgn-top-25   { margin-top: 25px !important; }
.mgn-top-30   { margin-top: 30px !important; }
.mgn-right-5  { margin-right: 5px !important; }
.mgn-right-10 { margin-right: 10px !important; }
.mgn-right-15 { margin-right: 15px !important; }
.mgn-right-25 { margin-right: 25px !important; }
.mgn-right-30 { margin-right: 30px !important; }
.mgn-btm-10   { margin-bottom: 10px !important; }
.mgn-btm-15   { margin-bottom: 15px !important; }
.mgn-btm-25   { margin-bottom: 25px !important; }
.mgn-btm-30   { margin-bottom: 30px !important; }
.mgn-left-5   { margin-left: 5px !important; }
.mgn-left-0   { margin-left: 0 !important; }
.mgn-left-10  { margin-left: 10px !important; }
.mgn-left-15  { margin-left: 15px !important; }
.mgn-left-25  { margin-left: 25px !important; }
.mgn-left-30  { margin-left: 30px !important; }

.pad-top-0    { padding-top: 0 !important; }
.pad-top-2    { padding-top: 2px !important; }
.pad-top-10   { padding-top: 10px !important; }
.pad-top-15   { padding-top: 15px !important; }
.pad-top-20   { padding-top: 20px !important; }
.pad-top-25   { padding-top: 25px !important; }
.pad-top-30   { padding-top: 30px !important; }
.pad-top-40   { padding-top: 40px !important; }
.pad-top-50   { padding-top: 50px !important; }
.pad-right-10 { padding-right: 10px !important; }
.pad-right-15 { padding-right: 15px !important; }
.pad-right-25 { padding-right: 25px !important; }
.pad-right-30 { padding-right: 30px !important; }
.pad-right-50 { padding-right: 50px !important; }
.pad-right-60 { padding-right: 60px !important; }
.pad-btm-0    { padding-bottom: 0 !important; }
.pad-btm-8    { padding-bottom: 8px !important; }
.pad-btm-10   { padding-bottom: 10px !important; }
.pad-btm-15   { padding-bottom: 15px !important; }
.pad-btm-25   { padding-bottom: 25px !important; }
.pad-btm-30   { padding-bottom: 30px !important; }
.pad-left-10  { padding-left: 10px !important; }
.pad-left-15  { padding-left: 15px !important; }
.pad-left-20  { padding-left: 20px !important; }
.pad-left-25  { padding-left: 25px !important; }
.pad-left-30  { padding-left: 30px !important; }
.pad-left-50  { padding-left: 50px !important; }
.pad-left-60  { padding-left: 60px !important; }

.pad-all-10   { padding: 10px !important; }
.pad-all-15   { padding: 15px !important; }

.height-100pc { height: 100% !important; }

.wdh-180 { width: 180px !important; }

.shadow-lgt {
  -webkit-box-shadow: var(--box-shadow-lgt);
  box-shadow: var(--box-shadow-lgt);
}

.chevron { 
  width: 230px;
  min-width: 90px;
  height: 100%; 
  display: inline-block;
}

.chevron:not(:first-child) { margin-left: -5px; }

.chevron.ch-lft:before {
  content: '';
  width: 10px;
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  background-color: var(--col-bkgrnd-chevron-2); 
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 100% 50%);
}

.chevron.ch-rgt:after {
  content: '';
  width: 10px;
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  background-color: var(--col-bkgrnd-chevron-2); 
  clip-path: polygon(0 0, 2px 0, 100% 50%, 2px 100%, 0 100%);
}

.chevron > .content { 
  margin-left: -1px;
  margin-right: -1px;
  width: calc(100% - 22px);
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  border-left: 1px solid var(--col-bkgrnd-chevron-2);
  border-right: 1px solid var(--col-bkgrnd-chevron-2);
  background-color: var(--col-bkgrnd-chevron-2); 
}

.chevron > .content > div { height: 100%; }

.chevron.ch-20:not(:first-child) { margin-left: -10px; }

.chevron.ch-20.ch-lft:before { width: 20px; }
.chevron.ch-20.ch-rgt:after { width: 20px; }

.chevron.ch-20 > .content { width: calc(100% - 40px); }

.dim-txt { color: var(--fnt-col-dim); }

.lit-txt { color: var(--fnt-col-lgt) }

.cntr-txt { text-align: center; }

.gap-exam-txt {
  padding-right: 75px;
  display: inline-block;
}

.scroller-vrt {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll-shadow-top {
  z-index: 999;
  position: relative;
  background-color: var(--col-wht);
  box-shadow: 0 15px 30px -12px rgb(0 0 0 / 50%);
}

.scroll-shadow-btm {
  z-index: 999;
  position: relative;
  background-color: var(--col-wht);
  box-shadow: 0 -15px 30px -25px rgb(0 0 0 / 50%);
}

.wrapword {
  white-space: -moz-pre-wrap !important;
  white-space: -webkit-pre-wrap;      
  white-space: -pre-wrap;            
  white-space: -o-pre-wrap;           
  white-space: pre-wrap;                  
  white-space: normal;
  word-wrap: break-word;                  
  word-break: break-all;
}


 
 
 
 
/*
 * Media queries ( max-widths: 667, 400 ).
 * When a snaphot is taken .grabbing is applied to .app-home, this should
 * revert styles to non-mobile and allow the snapshot to match thay found on desktop.
 */
 
@media only screen and ( max-width: 667px ) {
  
  .is-desktop { display: none; }
  .is-desktop.is-inline { display: none; }
  .is-mobile { display: block; }
  .is-mobile.is-inline { display: inline; }
  
  .app-home:not(.grabbing) .app-body { 
    padding: 0;
    overflow: visible; 
  }
  
  .app-home:not(.grabbing) .app-header,
  .app-study-planner .app-header { 
    max-height: 0; 
    overflow: hidden; 
  }
  
  .app-home:not(.grabbing) .app-footer,
  .app-study-planner .app-footer { display: none; }
   
  .app-home:not(.grabbing) .planner-wrapper { 
    overflow-x: auto; 
    overflow-y: hidden;
  }

  .app-home:not(.grabbing) .mob-planner-wrapper,
  .app-home:not(.grabbing) .planner > .content,
  .app-home:not(.grabbing) .exams,
  .app-home:not(.grabbing) .exams > .content,
  .app-home:not(.grabbing) .exams-header > .content,
  .app-home:not(.grabbing) .exam-stick,
  .app-home:not(.grabbing) .exam-stick > .content,
  .app-home:not(.grabbing) .exam-cell button,
  .app-home:not(.grabbing) .exam-footer button,
  .app-home:not(.grabbing) .mob-content,
  .app-home:not(.grabbing) .calendar .row div { 
    height: 100%; 
    max-height: 100%;
  }
  
  .app-home:not(.grabbing) .planner > .content { 
    padding: 0;
    width: auto;
    max-width: none;
    height: 100%; 
    overflow: visible;
    position: relative;
  }
  
  .app-home:not(.grabbing) .exams { 
    padding: 0 100px 0 115px;
    display: inline-block;
    overflow: hidden; 
    width: 100%;
  }
  
  .app-home:not(.grabbing) .exams-header {
    padding: 0 0 20px 0;
    flex: 0 0 15%;
  }
  
  .app-home:not(.grabbing) .exams-header > .content { 
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    align-content: flex-start;
    justify-content: flex-start;
    height: 100%; 
  }

  .app-home:not(.grabbing) .exams-body { 
    flex: 1 1 auto; 
    max-height: 85%;
    min-height: initial;
  }
  
  .app-home:not(.grabbing) .exams-footer { display: none; }
  
  .app-home:not(.grabbing) .exam-stick.exempt, 
  .app-home:not(.grabbing) .exam-stick.passed { max-height: calc(15% - 5px); }
  
  .app-home:not(.grabbing):not(.intro) .exams-group .exam-stick.completed { max-height: calc(15% - 5px); }
  
  .app-home:not(.grabbing) .exam-header .exam-status { padding-top: 6px; }  
  
  .app-home:not(.grabbing) .exam-header { height: 16%; } 
  .app-home:not(.grabbing) .exam-body { height: 78%; }
  .app-home:not(.grabbing) .exam-footer { height: 6%; }
  
  .app-home:not(.grabbing) .exam-stick:not(.exempt):not(.passed).still .exam-header,
  .app-home:not(.grabbing) .exam-stick.still .exam-body,
  .app-home:not(.grabbing) .exam-stick.still .exam-footer { min-height: 0 !important; }
  
  .app-home:not(.grabbing) .exam-cell {
    margin-bottom: 0;
    padding-bottom: 2px;
    height: calc(100% / 12);
  }
  
  .app-home:not(.grabbing) .calendar {
    top: 0;
    height: 100%;
    max-height: 100%;
  }
  
  .app-home:not(.grabbing) .mob-content .spacer-1 { height: 15%; }
  .app-home:not(.grabbing) .mob-content .spacer-2 { height: 16%; }
  .app-home:not(.grabbing) .mob-content .spacer-3 { height: 6%; }
  
  .app-home:not(.grabbing) .mob-stick { height: 85%; }
  
  .app-home:not(.grabbing) .mob-rows { height: 78%; }
  
  .app-home:not(.grabbing) .calendar-sticky > .mob-content { 
    width: 100%;
    position: absolute; 
  }
 
  .app-home:not(.grabbing) .calendar .row,
  .app-home:not(.grabbing) .calendar-sticky .row {
    margin-bottom: 0;
    padding-bottom: 2px;
    height: calc(100% / 12);
    position: relative;
    background-color: transparent;
  }
  
  .app-home:not(.grabbing) .calendar-sticky .row:not(:last-child):after { 
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    display: block;
    background-color: rgb(36, 55, 73);
  }
  
  .app-home:not(.grabbing) .calendar-sticky .row div {
    padding-left: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    background: rgb(49,71,92);
  }
  
  .app-home:not(.grabbing) .calendar-sticky .row:last-child div { border-bottom: none; }
  
  .app-home:not(.grabbing) .calendar .row div { background-color: var(--col-bkgrnd-wht-05); }
  
  
  /* Modals don't need :not(grabbing) as they are not under .app-home & they are not snapshotted. */
  
  .modal-cus { max-width: none; }
  
  .modal-cus .el-dialog__body { padding: 30px 20px; }
  
  .modal-cus.el-dialog--small { width: 90%; }
  
  .modal-cus h4 { font-size: 2rem; }
  
  .modal-cus h1 { 
    font-size: 2.2rem; 
    line-height: 2.6rem;
  }
  
  
  .modal-cus .modal-btns-wrapper,
  .modal-cus .modal-btns-grid { 
    width: 100%; 
    max-width: initial;
  }
  
  .btn-toggle .icon-tick {
    right: 10px;
    width: 20px;
  }
  
  .modal-sys .modal-btns-grid { grid-column-gap: 15px; }
  
  .modal-sys .period-selector, 
  .modal-sys .period-chart, 
  .modal-sys .period-cta { width: 85%; }
  
  .modal-sys .period-selector button {
    width: 50px;
    height: 50px;
  }
  
  .modal-sys .period-selector .duration { font-size: 3.2rem; }
  
  .modal-sys .period-chart div span:first-child { max-width: 150px; }
  
  .modal-ins .quad-wrapper {
    width: 100px;
    height: 100px;
  }
  
  .modal-ins .quad-wrapper span {
    font-size: 1.5rem;
    line-height: 100px;
  }
  
  .modal-ins .list-header { 
    padding: 0 0 20px 0; 
    font-size: var(--fnt-sze-reg);
    line-height: var(--lne-hgt-reg);
  }
  
  .modal-ins .list-item {
    padding: 0 0 10px 0; 
    color: var(--fnt-col-lgt);
    font-size: var(--fnt-sze-reg);
    line-height: var(--lne-hgt-reg);
  }
  
  .app-home:not(.grabbing) .btn-toggle:after {
    top: 14px;
    right: 4px;
    font-size: 1.4rem;
  }
  
  .app-home:not(.grabbing) .exam-section-title.title-1 { width: calc(100% / 16 * 3 - 10px); }
  .app-home:not(.grabbing) .exam-section-title.title-2 { width: calc(100% / 16 * 6 - 55px); }
  .app-home:not(.grabbing) .exam-section-title.title-3 { width: calc(100% / 16 * 1 - 10px); }
  .app-home:not(.grabbing) .exam-section-title.title-4 { width: calc(100% / 16 * 6 - 30px); }
  
  .app-home:not(.grabbing) .gap-exam-txt { padding-right: 70px; }
  
  .app-home:not(.grabbing) .btn-toggle .icon-tick {
    top: 14px;
    right: 8px;
    width: 18px;
  } 
  
  .app-home:not(.grabbing) .tny-mob-hide { font-style: normal; }
  
  .btn-reset {
    top: 0;
    width: 128px;
  }
  
  .study-planner { padding: 0; }
  
  .study-scrubber { padding: 30px 0; }
  
  .scrubber span:not(:last-child) { margin-right: 0; }
  
  .mob-header { display: block; }
  
  .btn-breadcrumb {
    font-size: var(--fnt-sze-hge);
    line-height: var(--lne-hgt-lrg);
  }
  
  .study-planner .item-lft { width: 105px; }
  
  .study-planner .daily-mood { width: 25px; }
  
  .icon-planner-plus { width: 30px; }
  
  .study-planner .daily-date span { 
    font-size: 14px; 
    line-height: 14px;
    text-transform: uppercase;
  }
  
  .study-planner .daily-task li span { font-size: 14px; }
  
  .txt-breadcrumb { font-size: 22px; }
  
  .study-breadcrumb { padding-bottom: 15px; }
  
  .study-breadcrumb .btns-grp { padding-top: 5px; }
  
  ::-webkit-scrollbar { 
    display: none;
    -webkit-appearance: none; 
  }
  
  .study-planner .scroller-vrt { padding-left: 0; }
  
  .btn-session { width: 240px; }
  
  .btn-session:first-child { margin-right: 0; }
  
  .btn-session.btn-toggle .icon-tick { right: 45px }
  
  .error-msg { max-width: 80%; }
  
  .btn-confirm .btn-grp button { padding: 0 15px; }

}

@media only screen and ( max-width: 400px ) {
  
  .modal-sys .period-selector, 
  .modal-sys .period-chart, 
  .modal-sys .period-cta { width: 100%; }
  
  .modal-sys .period-selector .duration { font-size: 2.6rem; }
  
  .modal-ins .modal-body { width: 100%; }
  
  .app-home:not(.grabbing) .mobile-intro .start-chevron .start-txt { font-size: 2rem }

  .app-home:not(.grabbing) .btn-toggle .icon-tick {
    top: 15px;
    right: 4px;
    width: 16px;
  } 
  
  .app-home:not(.grabbing) .exam-header .exam-title { font-size: 1.6rem; } 
  
  .app-home:not(.grabbing) .exam-stick.completed .exam-header .exam-title .icon-tick, .exam-header.tick .exam-title .icon-tick { top: 18px; }
  
  .modal-ins .quad-wrapper {
    width: 75px;
    height: 75px;
  }
  
  .modal-ins .quad-wrapper span {
    font-size: 1.1rem;
    line-height: 75px;
  }
  
  .modal-ins h1 { 
    padding: 20px 0;
  }
  
  .modal-ins h2 {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
  
  .modal-cus h4 {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
  
  .modal-ins .modal-footer,
  .modal-sys .modal-footer { padding-top: 15px; }
  
  .error-msg { max-width: 90%; }
  
  .btn-milestone-txt { max-width: 90%; }
  
  .btn-done-tasks { padding: 10px 25px; }
  
  .error-pg-code { font-size: 6em; }
  
  .error-pg-txt span { display: block; }

}

@media only screen and ( max-width: 400px ) and ( max-height: 750px ) {
  
  .app-home:not(.grabbing) .exam-stick.exempt,
  .app-home:not(.grabbing) .exam-stick.passed { max-height: 75px; }

  .app-home:not(.grabbing):not(.intro) .exams-group .exam-stick.completed { max-height: 75px; }
  
  .app-home:not(.grabbing) h2 { font-size: 1.5rem; }
  
  .app-home:not(.grabbing) .exam-header.tick .exam-title .icon-tick { 
    top: 15px;
    width: 16px;
  }
  
}

@media only screen and ( max-width: 400px ) and ( max-height: 650px ) {
  
  .app-home:not(.grabbing) .mobile-intro .mobile-logo { height: 60px; }
  
  .app-home:not(.grabbing) .mobile-intro .start-chevron { 
    margin-top: 6vw; 
    height: 80px;
  }
  
  .app-home:not(.grabbing) .mobile-intro .start-chevron .start-txt { 
    font-size: 1.7rem; 
    line-height: 2.3rem;
  }
  
  .modal-cus .el-dialog__body { padding: 15px; }
  
  .modal-cus.el-dialog--small { width: 95%; }
  
  .app-home:not(.grabbing) .cus-chkbox .label {
    font-size: var(--fnt-sze-reg);
    line-height: var(--lne-hgt-reg);
  }
  
  .modal-cookie-txt,
  .modal-cus .modal-footer { font-size: 1.1rem; }
  
  .app-home:not(.grabbing) .tny-mob-hide { 
    display: none; 
    font-style: normal;
  }
  
  .app-home:not(.grabbing) .exam-header .exam-status .text { margin-top: -2px; }
  
  .app-home:not(.grabbing) .exam-header .exam-status .dots { bottom: -3px; }
  
  .app-home:not(.grabbing) .exam-header.tick .exam-title .icon-tick { 
    top: 13px;
    width: 14px;
  }
  
  .app-home:not(.grabbing) .btn-toggle .icon-tick {
    top: 15px;
    right: 5px;
    width: 14px;  
  }
  
  .app-home:not(.grabbing) .exam-stick.exempt,
  .app-home:not(.grabbing) .exam-stick.passed { max-height: 68px; }

  .app-home:not(.grabbing):not(.intro) .exams-group .exam-stick.completed { max-height: 68px; }
  
  .app-home:not(.grabbing) .exam-header .exam-status { padding-top: 3px; }
  
  .modal-ins h2 {
    font-size: 1.3rem;
    line-height: 2rem;
  }
  
}

@media only screen and ( max-width: 360px ) {
  
  .modal-ins .list-item { padding-bottom: 8px; }
  
  .modal-checkboxes { margin: 5px auto; }
  
  .cus-chkbox { margin-bottom: 8px; }
  
  .cus-chkbox .label { font-size: 1.5rem; }
  
  .btn-confirm .btn-grp button { padding: 0 10px; }
  
}
 
 
 
 
 
 



/*** Styles end... */
